var GRAY_ICON = 'https://cdn.hyperdev.com/us-east-1%3A3d31b21c-01a0-4da2-8827-4bc6e88b7618%2Ficon-gray.svg';
var WHITE_ROCKET_ICON = 'https://cdn.glitch.com/c69415fd-f70e-4e03-b43b-98b8960cd616%2Fwhite-rocket-ship.png?1495811896182';
var STORYPOINT = 'https://trello-track-proxy.k33nice.workers.dev';

function showIframe(t) {
  return t.popup({
    title: 'Authorize to continue',
    url: './authorize.html'
  });
}

var onLogClick = function (t, opts) {
    return t.popup({
        title: 'Time Spent',
        url: './time-spent.html',
        height: 80
    });
};

var onStoryClick = function (t, opts) {
    return t.popup({
        title: 'Set Story Points',
        url: './story-point.html'
    });
};

window.TrelloPowerUp.initialize({
    'card-buttons': function (t, opts) {
   return t.getRestApi()
      .isAuthorized()
      .then(function(isAuthorized) {
        if (isAuthorized) {
            return [{
                icon: GRAY_ICON,
                text: 'Log time',
                callback: onLogClick,
                condition: 'edit'
            }, {
                icon: STORYPOINT,
                text: 'Storypoint',
                callback: onStoryClick,
                condition: 'edit'
            }];
        } else {
          return [{
            text: 'Authorize',
            callback: showIframe
          }];
        }
      });
    },
    'card-badges': function(t, options) {
        return t.get('card', 'shared', 'storypoint')
            .then(function(estimate) {
                if (!estimate) {
                    return [];
                }
                return [{
                    icon:  STORYPOINT,
                    text: estimate,
                    color: 'green',
                }];
            });
    },
    'card-back-section': function(t, options){
        return {
            title: 'Time spent',
            icon: GRAY_ICON, // Must be a gray icon, colored icons not allowed.
            content: {
                type: 'iframe',
                url: t.signUrl('./worklogs.html'),
                height: 100, // Max height is 1500.
                'max-height': 300,
            }
        };
    }
}, {
    appName: 'Trello Time Track',
    appKey: '87ea8b13107b6b0b4a5a08930ce4acca'
});
